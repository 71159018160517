import { useState, useEffect } from "react";
import { TableBody, TableCell, TableHead, TableRow, Box, CircularProgress, FormControlLabel } from "@material-ui/core";
import { Button, Checkbox, Table, Input, Select, MuiDatePicker } from "./MaterialUIComponents";
import { useStyles } from "./styles";
import { useNavigate } from 'react-router-dom';
import Layout from '../TopNav/Topnav';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import { usePatientPaymentsSummary, usePaymentsSummary } from "../../hooks/reactQuery/useCheckoutQuery";


export default function Payments() {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [hidePaid, setHidePaid] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [date, setDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDateChange = (date) => {
    setDate(date);
  };

  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const openSummaryPage = (userId) => {
    navigate(`/dashboard/payments/${userId}`);
  }

  // const handleDateChange = useCallback((date) => {
  //   setDate(date);
  // }, []);
  const { isLoading: PaymentsSummaryLoading, paymentSummary } = usePaymentsSummary();
  const { isLoading, isError, patientPaymentSummary } = usePatientPaymentsSummary(search, selectedStatus, hidePaid, search.length == 0 || search.length > 2);
  let users = [];
  let totalPages = 1;
  if(patientPaymentSummary && patientPaymentSummary?.users?.data){
    users = patientPaymentSummary?.users?.data;
    totalPages = patientPaymentSummary?.users?.last_page;
  }
  // const { users: payments } = patientPaymentSummary;

  return (
    <Layout isProfile={true}>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <div className={classes.container}>
          {/* Summary Section */}
          <div className={classes.summaryGrid}>
            {[t("total-overdue"), t("total-upcoming-payments"), t("total-paid")].map(
              (title, index) => (
                <div key={index} className={classes.summaryCard}>
                  <h3 className={classes.summaryTitle}>{title}</h3>
                  <p className={classes.summaryValue}>
                    {paymentSummary.currency +
                      " " +
                      [
                        numberWithCommas(paymentSummary.overDueTotal),
                        numberWithCommas(paymentSummary.currentMonthDueTotal),
                        numberWithCommas(paymentSummary.totalPaid),
                      ][index]}
                  </p>
                </div>
              )
            )}
          </div>

          {/* Filters Section */}
          <div className={classes.filterSection}>
            <div className={classes.searchBox}>
              <SearchIcon className={classes.searchIcon} />
              <Input
                placeholder={t('search-patient-placeholder')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <Select
              label={t("filter-by-status")}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              options={[
                { value: "", label: t("all-status") },
                { value: "paid", label: t("paid") },
                { value: "pending", label: t("pending") },
                { value: "overdue", label: t("overdue") },
              ]}
            />
            {/* <MuiDatePicker placeholder="Select Due Date" value={date} onChange={handleDateChange} /> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={hidePaid}
                  onChange={(e) => setHidePaid(e.target.checked)}
                />
              }
              label={t("hide-payments")}
            />
          </div>

          {/* Table */}

          <Table>
            <TableHead>
              <TableRow>
                {[
                  t("Patient-Name"),
                  t("Case ID"),
                  t("Case Type"),
                  t("Arches"),
                  t("Treatment-Type"),
                  t("Total-Cost"),
                  t("Amount-Paid"),
                  t("Outstanding"),
                  t("Payment-Plan"),
                  t("Due-Date"),
                  t("Status"),
                  t("Action"),
                ].map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                let smilePlan = user.smile_plan_preferred;
                let arches = smilePlan.number_of_aligner_lower && smilePlan.number_of_aligner_upper ? t("double") : t("single");
                let tpname = t("initial-treatment");
                let firstPayment = user.payment_completed[0];
                if(!firstPayment){
                  firstPayment = user.payments[0];
                }
                let firstUncompletedPayment = user.payment_uncompleted.length > 0 ? user.payment_uncompleted[0] : null;
                let currency = firstPayment.currency;
                let totalCost = user.payments.reduce((acc, payment) => acc + payment.total, 0);
                let amountPaid = user.payments.reduce((acc, payment) => acc + (payment.paid ? payment.total : 0 ), 0);
                let outstanding = totalCost - amountPaid;
                let payments_count = user.payments.length;
                let unpaid_payments_count = user.payment_uncompleted.length;
                let paymentPlan = payments_count > 1 ? "Pay in " + payments_count : "One-Time";
                let dueDate = "";
                if(firstUncompletedPayment){
                  dueDate = firstUncompletedPayment.withdraw_date;
                }
                let status = "Pending";
                if(user.client_status != "Good"){
                  status = "Overdue";
                }if(unpaid_payments_count == 0){
                  status = "Paid";
                }
                return (
                  <TableRow key={user.id}>
                    <TableCell>{user.full_name}</TableCell>
                    <TableCell>#{user.id}</TableCell>
                    <TableCell>{smilePlan.plan_type}</TableCell>
                    <TableCell>{arches}</TableCell>
                    <TableCell>{tpname}</TableCell>
                    <TableCell>{currency} {totalCost}</TableCell>
                    <TableCell>{currency} {amountPaid}</TableCell>
                    <TableCell>{currency} {outstanding}</TableCell>
                    <TableCell>{paymentPlan}</TableCell>
                    <TableCell>{dueDate}</TableCell>
                    <TableCell>
                      <span
                        className={
                          classes.statusBadge +
                          " " +
                          (status === "Paid"
                            ? classes.statusPaid
                            : status === "Overdue"
                            ? classes.statusOverdue
                            : classes.statusPending)
                        }
                      >
                        {status}
                      </span>
                    </TableCell>
                    <TableCell>
                      {status != "Paid" && (
                        <Button
                          onClick={() => {
                            openSummaryPage(user.id);
                          }}
                        >
                          {t("pay-now")} {currency} {firstUncompletedPayment.total}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {/* Pagination */}
          <div className={classes.pagination}>
            <Button
              variant="outlined"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              {t("previous")}
            </Button>
            <span>
              {t("page")} {currentPage} {t('of')} {totalPages}
            </span>
            <Button
              variant="outlined"
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              {t("next")}
            </Button>
          </div>
        </div>
      )}
    </Layout>
  );
}
