import { useState, useEffect } from "react";
import { TableBody, TableCell, TableHead, TableRow, Box, CircularProgress, FormControlLabel } from "@material-ui/core";
import { Button, Checkbox, Table, Input, Select, MuiDatePicker } from "./MaterialUIComponents";
import { useStyles } from "./styles";
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../TopNav/Topnav';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { useCheckout, usePendingPayments } from "../../hooks/reactQuery/useCheckoutQuery";
import StripeCheckoutForm from "../Checkout/StripeCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export default function PaymentsSummary() {
  const classes = useStyles();
  let { userId } = useParams();
  const { checkout, isPending } = useCheckout();
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const {
      isLoading,
      isError,
      data,
    } = usePendingPayments(userId);
  const { pendingPayments, overDueTotal, totalRemaining, totalPaid, user } = data;
  let firstUnPaidIndex = pendingPayments?.findIndex(payment => payment.paid == 0);
  const handleCheckout = async (e) => {
    e.preventDefault();
    checkout(
      { userId, planId: pendingPayments[0].plan_id },
      {
        onSuccess: async (data) => {
          setClientSecret(data.clientSecret);
          setTimeout(() => {
            const element = document.getElementById("stripe-container");
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }, 1000);
        },
      }
    );
  };
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };
const options = {
    clientSecret,
    appearance,
  };


  return (
    <Layout isProfile={true}>
        {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
    <div className={classes.container}>
      {/* Summary Section */}
      <div className={classes.summaryGrid}>
        <h1 style={{fontSize: "1.5em"}}>{t("Patient-Name")}: {user.full_name} (# {user.id})</h1>
      </div>
      <div className={classes.summaryGrid}>
        {[t("total-overdue"), t("total-remaining"), t("total-paid")].map((title, index) => (
          <div key={index} className={classes.summaryCard}>
            <h3 className={classes.summaryTitle}>{title}</h3>
            <p className={classes.summaryValue}>{pendingPayments[0].currency +
                      " " +[numberWithCommas(overDueTotal), numberWithCommas(totalRemaining), numberWithCommas(totalPaid)][index]}</p>
          </div>
        ))}
      </div>

      {/* Table */}
      
      <Table>
        <TableHead>
          <TableRow>
            {[t("Due-Date"), t("Amount"), t("Status"), t("Action")].map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingPayments.map((payment, index) => {
            let canPay = !payment.paid && firstUnPaidIndex == index;
            let status = payment.paid ? "paid" : ( moment(payment.withdraw_date, "DD-MM-YYYY").isAfter(moment()) ? "not-due" : "overdue" );
            return (
              <TableRow key={payment.id}>
                <TableCell>{payment.withdraw_date}</TableCell>
                <TableCell>{payment.currency} {numberWithCommas(payment.total)}</TableCell>
                <TableCell>
                  <span className={classes.statusBadge + " " + (status === "paid" ? classes.statusPaid : status === "overdue" ? classes.statusOverdue : (status === "settle-now" ? classes.statusSettle : classes.statusPending ))}>
                    {t(status)}
                  </span>
                </TableCell>
                <TableCell>{canPay && <Button onClick={(e) => handleCheckout(e)} className={classes.button} variant="contained" disabled={isPending || clientSecret}>{isPending ? t("processing") : (clientSecret ? t("waiting") : t("pay-now"))}</Button>}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <div id="stripe-container" style={{width:"30%", margin:"auto"}}>
      {!clientSecret ? (
        null
        ) : (
        <Elements options={options} stripe={stripePromise}>
            <StripeCheckoutForm return_url={process.env.REACT_APP_STRIPE_REDIRECT + "dashboard/payments/" + userId}/>
        </Elements>
        )}
      </div>
    </div>
    )}
    </Layout>
  );
}
