import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "90%",
    padding: "32px 0",
    margin: "0 auto",
    // [theme.breakpoints.up('lg')]: {
    //   width: "95%",
    //   maxWidth: "95%",
    // },
  },
  summaryGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "16px",
    marginBottom: "3rem",
  },
  summaryCard: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "24px",
  },
  summaryTitle: {
    fontSize: "16px",
    color: "#666",
    fontWeight: "500",
  },
  summaryValue: {
    fontSize: "24px",
    fontWeight: "bold",

  },
  filterSection: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    alignItems: "center",
    marginBottom: "1rem"
  },
  searchBox: {
    position: "relative",
  },
  statusBadge: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "16px",
    padding: "4px 10px",
    fontSize: "12px",
    fontWeight: "500",
  },
  statusPaid: {
    backgroundColor: "#D1FAE5",
    color: "#065F46",
  },
  statusSettle: {
    backgroundColor: "#c7f7fe",
    color: "#095461",
  },
  statusPending: {
    backgroundColor: "#FEF3C7",
    color: "#92400E",
  },
  statusOverdue: {
    backgroundColor: "#FECACA",
    color: "#991B1B",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginTop: "16px",
  },
  loadingBox: {
    margin: "8% auto",
    textAlign: "center",
    padding: 30,
  },
  loading: {
    textAlign: "center",
    margin: "0 auto",
    color: "#ff2c46",
  },
  searchIcon: {
    position: "absolute",
    left: theme.spacing(2.5),
    top: "50%",
    height: "16px",
    width: "16px",
    color: theme.palette.text.secondary,
    transform: "translateY(-50%)"
  },
  button: {
    backgroundColor: theme.palette.error.main,
    borderRadius: "5px",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
