import React, { useState } from "react";
import Layout from "../TopNav/Topnav";
import { useTranslation } from "react-i18next";
import {
  useCheckout,
  useCheckPlan,
} from "../../hooks/reactQuery/useCheckoutQuery";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Divider,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
  },
  card: {
    width: "100%",
    padding: theme.spacing(2),
  },
  cardDetails: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "1.25rem !important",
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.875rem",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.875rem",
    flexDirection: "column",
    gap: "5px",
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
  },
  button: {
    width: "100%",
    backgroundColor: theme.palette.error.main,
    borderRadius: "5px",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  loadingBox: {
    margin: "8% auto",
    textAlign: "center",
    padding: 30,
  },
  loading: {
    textAlign: "center",
    margin: "0 auto",
    color: "#ff2c46",
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export default function CheckoutCard() {
  const classes = useStyles();
  let { userId, id, planId } = useParams();
  const {
    isLoading: PlanLoading,
    error,
    plan,
  } = useCheckPlan(userId, planId, id);
  const { checkout, isPending } = useCheckout();
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    checkout(
      { userId, planId: id },
      {
        onSuccess: async (data) => {
          setClientSecret(data.clientSecret);
        },
      }
    );
  };
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  console.log("PlanLoading", PlanLoading);
  return (
    <Layout isProfile={true}>
      {PlanLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <div className={classes.root}>
          <Card className={classes.card}>
            <CardContent className={classes.cardDetails}>
              <Typography className={classes.title}>
                {t('aligner-treatment')}
              </Typography>
              <div className={classes.body}>
                <div className={classes.details}>
                  <span>{t('aligner-treatment')}</span>
                  <span>
                    {plan.currency} {numberWithCommas(plan.total)}
                  </span>
                </div>
                <div className={classes.details} style={{ fontWeight: 600 }}>
                  <span>{t('aligner-treatment-due-today')}</span>
                  <span>{plan.currency} {numberWithCommas(plan.price)}</span>
                </div>
                <Divider style={{ margin: "8px 0" }} />
                <div className={classes.total}>
                  <span>{t('total-today')}</span>
                  <span>{plan.currency} {numberWithCommas(plan.price)}</span>
                </div>
              </div>
            </CardContent>
            <CardActions>
              {!clientSecret ? (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleCheckout}
                  disabled={isPending}
                >
                  {isPending ? t("processing") : t("checkout")}
                </Button>
              ) : (
                <Elements options={options} stripe={stripePromise}>
                  <StripeCheckoutForm return_url={process.env.REACT_APP_STRIPE_REDIRECT + "dashboard/users/" + userId}/>
                </Elements>
              )}
            </CardActions>
          </Card>
        </div>
      )}
    </Layout>
  );
}
