import {Box, Button, Chip, MenuItem, Select, Typography} from '@material-ui/core'
import axios from '../../util/axios'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircularProgress from "@material-ui/core/CircularProgress";
import { iconColors } from '../../util/functions';
import TreatmentPlanStyles from "./treatment-plan.styles";
import { saveAs } from "file-saver";

export default function TreatmentPlan() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    let { id } = useParams();

    const [tabNavProps, setTabNavProps ] = useState([
        {
            id: 0,
            label: 'Treatment-Plan',
            planIndex : 'data',
            procedureIndex : 'ipr_info',
            onClick : () =>  setIndex(0),
            ref : useRef(),
            show: true,
        },
        {
            id: 1,
            label: 'Refinement-Plan',
            planIndex : 'refinement_data',
            procedureIndex : 'ipr_info_refinement',
            onClick : () =>  setIndex(1),
            ref : useRef(),
            show: false
        },
        {
            id: 2,
            label: 'MCA-Plan',
            planIndex : 'mca_data',
            procedureIndex : 'ipr_info_mca',
            onClick : () =>  setIndex(2),
            ref : useRef(),
            show: false
        },
    ]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [procedureVisible, setProcedureVisible] = useState(false);
    const [yourSmilePlanResponse, setYourSmilePlanResponse] = useState([]);
    const [treatmentPlan, setTreatmentPlan] = useState(null);
    const [refinementPlan, setRefinementPlan] = useState(null);
    const [MCAPlan, setMCAPlan] = useState(null);
    const [iprInfo, setIprInfo] = useState([]);
    const [titleWidth, setTitleWidth] = useState(0);
    const [titleWidthTransform, setTitleWidthTransform] = useState(0);
    const [statues, setStatues] = useState(['','','']);
    const classes = TreatmentPlanStyles({ index, titleWidth, titleWidthTransform: titleWidthTransform, procedureVisible, iprInfo });
    const [selectedIprUrl, setSelectedIprUrl] = useState('');
    const [newIPRSheets, setNewIPRSheets] = useState([]);
    const [showPurchase, setPurchase] = useState(false);
    
    function openFile(e) {
        const win = window.open(e.currentTarget.id, "_blank");
        win.focus();
    }
    function openPaymentPage(smilePlanId) {
        navigate(`/dashboard/users/${id}/smile-plan/${smilePlanId}/plans`);
    }
    function openStepFile(e) {
        setSelectedIprUrl(e.target.value)
        let filepath=e.target.value.split("/");
        let fileName=filepath[4];
        saveAs(process.env.REACT_APP_AWS_URL+e.target.value,fileName);  
    }
    
    const getProceduresWithIPR = (procedures) => {
        return procedures.filter((procedure) => "url" in procedure)
    }

    function openInNewTab(url) {
        window.open(url, "_blank").focus();
    }
    
    const handle3DViewerOnClick = () => {
      if (yourSmilePlanResponse?.designer_3d_file) {
        openInNewTab(yourSmilePlanResponse?.designer_3d_file);
        return;
      }
      navigate(`/dashboard/users/${id}/yoursmileplan/3d-viewer`);
    };
    
    useEffect(() => {
        if (yourSmilePlanResponse && iprInfo['procedures'] && iprInfo["procedures"].length > 0) {
            const newIPRSheetsArray = getProceduresWithIPR(iprInfo["procedures"]);
            setNewIPRSheets(newIPRSheetsArray);
            setSelectedIprUrl('');
        }
    }, [iprInfo]);

    const handlePlanStatus = (status) => {
      let badge;
      switch (status) {
        case "completed":
          badge = (
            <Chip
              className={`${classes.chip} ${classes.completed}`}
              label={t("Completed")}
            />
          );
          break;
        case "active":
          badge = (
            <Chip
              className={`${classes.chip} ${classes.active}`}
              label={t(status)}
            />
          );
          break;
        case "replaced":
          badge = (
            <Chip
              className={`${classes.chip} ${classes.replaced}`}
              label={t(status)}
            />
          );
          break;
        case "pending":
          badge = (
            <Chip
              className={`${classes.chip} ${classes.pending}`}
              label={t("pending-procedure")}
            />
          );
          break;
        default:
          badge = <Chip className={classes.chip} label={t("upcoming")} />;
      }
      return badge;
    };
    
    const fetchTreatmentPlan = async () =>{
        setProcedureVisible(false);
        setLoading(true)
        try {
            const result = await axios.get("admin/v1/users/" + id + "/smile-plan/preferred")
            if(result?.data?.success){
                let array = result?.data[tabNavProps[index].planIndex];
                let ipr_info = result?.data[tabNavProps[index].procedureIndex];
                const plansStatus = [...statues];
                setYourSmilePlanResponse(array);
                setIprInfo(ipr_info);
                if(result?.data?.data){
                    const dataTP = result.data.data
                    setTreatmentPlan({data: dataTP, ipr: result?.data?.ipr_info});
                    plansStatus[0] = dataTP?.status;
                    if (dataTP?.status === "upcoming") {
                        plansStatus[0] = "active";
                    } else {
                        plansStatus[0] = dataTP?.status;
                    }
                    if(dataTP?.user.status == "Your Smile Plan"){
                        setPurchase(true);
                    }
                }
                if(result?.data?.refinement_data){
                    tabNavProps[1].label = result?.data?.refinement_data?.name;
                    tabNavProps[1].show = true;
                    const dataRTP = result.data.refinement_data;
                    setRefinementPlan({data: dataRTP, ipr: result?.data?.ipr_info_refinement});
                    if (dataRTP?.status === "pending") {
                        plansStatus[1] = "active";
                    } else {
                        plansStatus[1] = dataRTP?.status;
                    }
                }

                if(result?.data?.mca_data){
                    tabNavProps[2].label = result?.data?.mca_data?.name;
                    tabNavProps[2].show = true;
                    const dataMCA = result?.data?.mca_data;
                    setMCAPlan({data : dataMCA, ipr: result?.data?.ipr_info_mca});
                    if (dataMCA?.status === "pending") {
                        plansStatus[2] = "active";
                    } else {
                        plansStatus[2] = dataMCA?.status;
                    }
                }
                let activeIndex = plansStatus.indexOf("active");
                if (activeIndex > -1 ) {
                    setIndex(activeIndex);
                } else {
                  setIndex(0);
                }
                setStatues([...plansStatus]);
            }
        }catch(err) { 
            console.log("error=", err)
        }finally{
            setLoading(false);
        }
    }

    useEffect(()=>{
        if(tabNavProps[index]?.ref?.current){  
            setTitleWidth(tabNavProps[index]?.ref?.current?.clientWidth);
            setTitleWidthTransform(tabNavProps[index]?.ref?.current?.offsetLeft);
        }
        if(index === 0){
            setYourSmilePlanResponse(treatmentPlan?.data);
            setIprInfo(treatmentPlan?.ipr)
        }else if(index === 1){
            setYourSmilePlanResponse(refinementPlan?.data);
            setIprInfo(refinementPlan?.ipr)
        }else if(index === 2){
            setYourSmilePlanResponse(MCAPlan?.data);
            setIprInfo(MCAPlan?.ipr)
        }
    }, [index])

    useEffect(() => {
        const ac = new AbortController()
        void fetchTreatmentPlan();
        return () => ac.abort();
    }, []);
    
  return (
    <Box className={classes.wrapper}>
        <Box style={{padding:'1rem'}}>
            <Box className={classes.flex} style={{ gap: '3rem'}}>
                {tabNavProps.map((res, key)=> res.show && (
                    <div id='tabtitle' ref={res?.ref} key={key}>
                        <Typography style={{cursor:'pointer'}} className={`${classes.typoWrite} ${classes.fontSizeLG} ${classes.color} ${classes.bold} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`} onClick={res.onClick} key={key}>{t(res?.label)}</Typography>
                    </div>
                ))}    
            </Box>
            {(refinementPlan?.data != null || MCAPlan?.data != null) && <Box className={classes.tabTitles}></Box>}
        </Box>

        {loading ? <Box className={classes.loadingBox}><CircularProgress className={classes.loading}/></Box> :
        <>
        <Box style={{padding: '0 1rem 1rem 1rem'}}>
            {yourSmilePlanResponse?.treatment_length && (
                    <Box className={classes.flex}>
                        <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color}`}>
                            {t('Treatment-length')}: </Typography>
                        <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color} ${classes.thin} ${classes.marginHorizantal} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                            {yourSmilePlanResponse?.treatment_length} {t('months')}
                        </Typography>
                        {handlePlanStatus(yourSmilePlanResponse?.status)}
                    </Box>
                )}

            {yourSmilePlanResponse?.number_of_aligner_upper !== null &&
                    yourSmilePlanResponse?.number_of_aligner_upper !== undefined && (
                    <Box className={classes.flex}>
                        <Typography className={`${classes.typoWrite} ${classes.fontSizeMD   } ${classes.color}`}>
                            {t('Aligners-top')}:
                        </Typography>
                        <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color} ${classes.thin} ${classes.marginHorizantal} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                            {yourSmilePlanResponse?.number_of_aligner_upper}
                        </Typography>
                    </Box>
                )}
            {yourSmilePlanResponse?.number_of_aligner_lower !== null &&
                    yourSmilePlanResponse?.number_of_aligner_lower !== undefined && (
                    <Box className={classes.flex}>
                        <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color}`}>
                            {t("Aligners-bottom")}:
                        </Typography>
                        <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color} ${classes.thin} ${classes.marginHorizantal} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                            {yourSmilePlanResponse?.number_of_aligner_lower}
                        </Typography>
                    </Box>
                    )}
            <Box className={classes.flex}>
                <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color}`}>
                    {t('Procedures-required')}:</Typography>
                <Typography className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color} ${classes.thin} ${classes.marginHorizantal} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                    {iprInfo?.ipr_required ? `Yes (Before step ${iprInfo?.ipr_steps})`
                    : "No"}
                </Typography>
                {iprInfo?.ipr_required && <Typography style={{color:'blue', cursor:'pointer'}} onClick={() => setProcedureVisible((prev) => !prev)} className={`${classes.typoWrite} ${classes.fontSizeMD} ${classes.color} ${classes.thin} ${classes.marginHorizantal} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEina}`}>
                  {procedureVisible ? t('hide') : t('show-more')}
                </Typography>}
            </Box>
            <Box className={classes.procedureDisplay}>
                {iprInfo?.procedures?.map((res, key)=>(
                    <Box key={key} className={classes.flex} style={{marginTop:'1rem',alignItems:'center'}}>
                        <p className={`${classes.color} ${i18n.language == 'ar' ? classes.fontFamilyAlmarai : classes.fontFamilyEinaLight}`}>{`${t('before')} ${t('Step')} ${res?.step}`}:</p>
                        <Box className={classes.flex} style={{marginLeft:'0.3rem'}}>
                            {res?.procedure_type?.map((re, key2) => (
                              <div key={key2}>
                                <Typography
                                    className={classes.typeIcon}
                                    style={{
                                        backgroundColor: iconColors[re]?.background ?? '#3CC7D3',
                                        color:iconColors[re]?.color ?? 'black'
                                    }}
                                >
                                    {re}
                                </Typography>
                              </div>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
        <Box className={classes.btnBoxWrapper}>
            <Button
            className={classes.btnTypo}
            primary
            id={yourSmilePlanResponse?.guide_url}
            onClick={() => openPaymentPage(yourSmilePlanResponse.id)}
            disabled={!showPurchase || index !=0 || statues[index] !== "active"}
            >
                {t('approve')}
            </Button>
            <Button
            className={classes.btnTypo}
            primary
            id={yourSmilePlanResponse?.guide_url}
            onClick={(e) => openFile(e)}
            disabled={statues[index] !== "active" || !yourSmilePlanResponse?.guide_url && true}
            >
            {t('View-Treatment-Plan')}
            </Button>
            <Button
            className={classes.btnTypo}
            primary
            disabled={
                statues[index] !== "active" ||
                (!yourSmilePlanResponse?.designer_3d_file &&
                    (yourSmilePlanResponse?.three_dimension_smile?.length === 0 ||
                        !yourSmilePlanResponse?.three_dimension_smile))
            }
            onClick={handle3DViewerOnClick}
            >
            {t('View-3D-Smile')}
            </Button>
            { newIPRSheets.length > 0 ?
                (
                    <Select
                        value={selectedIprUrl}
                        onChange={ openStepFile}
                        displayEmpty
                        disabled={statues[index] !== "upcoming" && statues[index] !== "active"}
                    >
                        <MenuItem value="" disabled>
                        Select IPR Step
                        </MenuItem>
                        { 
                            newIPRSheets.map((procedure, index) => (
                            <MenuItem key={index} value={procedure.url}>
                                IPR Step {procedure.step}
                            </MenuItem> 
                            ))
                        }
                    </Select>
                ) :
                <Button
                    className={classes.btnTypo}
                    primary
                    id={yourSmilePlanResponse?.ipr_url}
                    disabled={statues[index] !== "active" ||
                        (!yourSmilePlanResponse?.ipr_url ||
                            !yourSmilePlanResponse?.ipr_is_active) &&
                        true
                    }
                    onClick={(e) => openFile(e)}
                >
                    {t('View-IPR')}
                </Button>
            }
        </Box>
        </>}
    </Box>
  )
}
