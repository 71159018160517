import { useQuery, useMutation, } from "@tanstack/react-query";
import { checkPlan, fetchPlans, checkout, getUserPendingPayments, PaymentsSummary, PatientPaymentsSummary } from "../../api/checkout";


export const useCheckout = () => {
    const { data, error, isError, isLoading, mutateAsync } = useMutation({
        mutationKey: ["checkout"],
        mutationFn: ({userId, planId}) => checkout(userId, planId),
    });
    return { isPending: isLoading, error, isError, checkout: mutateAsync}
};

// export const usePurchaseStatus = () => {
//     const { data, isLoading, isError, failureCount } =  useQuery({
//         queryKey: ["purchaseStatus"],
//         queryFn: () => purchaseStatus(),
//         retry: 3,
//         retryDelay: 400,
//     });
//     return { order: isError || isLoading? {} : data, isLoading, isError, failureCount}
// }
export const usePaymentsSummary = () => {
    const { data, isLoading, isError } =  useQuery({
        queryKey: ["payments_summary"],
        queryFn: () => PaymentsSummary(),
    });
    return { paymentSummary: isError || isLoading? {overDueTotal: 0, currentMonthDueTotal: 0, totalPaid: 0, currency: ""} : data, isLoading, isError}
}
export const usePatientPaymentsSummary = (search, selectedStatus, hidePaid, enabled) => {
    const { data, isLoading, isError } =  useQuery({
        queryKey: ["payments_summary",search, selectedStatus, hidePaid ],
        queryFn: () => PatientPaymentsSummary(search, selectedStatus, hidePaid),
        enabled,
        keepPreviousData: true,

    });
    return { patientPaymentSummary: isError || isLoading? {users:[]} : data, isLoading, isError}
}
export const usePendingPayments = (userId) => {
    const { data, isLoading, isError } =  useQuery({
        queryKey: ["pendingPayments"],
        queryFn: () => getUserPendingPayments(userId),
    });
    return { data: isError || isLoading? {} : data, isLoading, isError}
}

export const usePlans = (userId, planId) => {
    const { data, isLoading, isError, isRefetching } = useQuery({
      queryKey: ["plans"],
      queryFn: () => fetchPlans(userId, planId),
      refetchOnMount: false,
    });
    return { plans: isError || isLoading || isRefetching? []: data, isLoading, isError}
  };

export const useCheckPlan = (userId, smilePlanId, planId) => {
const { data, isLoading, isError, isRefetching } = useQuery({
    queryKey: ["plan", planId],
    queryFn: () => checkPlan(userId, smilePlanId, planId),
    refetchOnMount: false,
});
return { plan: isError || isLoading || isRefetching? {}: data, isLoading, isError}
};