import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { clinicImpersonated, scanType } from "../../util/functions";
import { useTranslation } from "react-i18next";
import ProcedureHeader from "./procedure-header.component";
import ScanFiles from "./ScanFiles";
import axios from "../../util/axios";
import { Checkbox } from "@shopify/polaris";
import { TabsControllerContext } from "../../Context/Tabs/TabsProvider";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai-semi !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    box: {
      display: "flex",
      flexWrap: "wrap",
    },
    titleStyle: {
      height: "16px",
      color: "#303030",
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "22px",
    },
    titleBox: {
      height: "48px",
      width: "360px",
    },
    replacedTitleBox: {
      marginTop: '2rem',
      marginBottom: '1rem',
    },
    substituteTitleBox: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    loadingBox: {
      margin: "0 auto",
      textAlign: "center",
      padding: 30,
      color: "#FF2C46",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
    errorMsg: {
      color: "#D72C0D",
      fontSize: "12px !important",
      marginTop: "1rem",
    },
  };
});

export default function AppointmentScans({
  customStyle = {},
  userId,
  disable = false,
  data = { data: {}, isAllProvided: null },
  handleTabsExpand = () => {},
  index = 0,
  appointmentId = null,
  appointmentType = "scans",
  openCloseTabs,
  scrollToExpandedTab,
}) {
  const { data: scansData, isAllProvided, scanner_placeholders } = data;
  const { clinicNotifications } = useContext(TabsControllerContext);
  const classes = useStyles();
  const showSendImpressionCheckbox = clinicNotifications?.country.code === "LB";
  const [iconScan, setIconScan] = useState(isAllProvided);
  const [checkedScanFiles, setCheckedScanFiles] = useState(scansData?.sent_via_email === 1);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [imagePreview, setImagePreview] = useState(scansData);
  const [errorMsg, setErrorMsg] = useState("");
  const { t, i18n } = useTranslation();

  const handleChangeScanFiles = async (check) => {
    setCheckedScanFiles(check);
    await handleSave(null, check);
  };

  const handleSave = async (items = null, check = false) => {
    setErrorMsg("");
    const form_data = new FormData();
    if (items) {
      items.forEach((item) => {
        form_data.append(item.key, item.value);
      });
    }
    form_data.append("sent_via_email", check);
    form_data.append("procedure", appointmentType?.procedure);
    form_data.append("user_scan_info_id", appointmentId);
    form_data.append("id", imagePreview?.id);
    form_data.append("parent_id", imagePreview?.parent_id);
    try {
      const res = await axios.post(
        `/admin/v2/users/${userId}/case-record-scans`,
        form_data,
      );
      if (res.data.success) {
        const data = res.data;
        setIconScan(check ? check : data.isAllProvided);
        handleDataFetched(data);
        setSuccess(true);
      }
      setDisabled(false);
    } catch (err) {
      setErrorMsg("Upload failed. Please try again.");
      setSuccess(false);
      setDisabled(false);
      console.log(err);
    }
  };

  const handleDataFetched = (value) => {
    if (value.data) {
      setImagePreview({
        ...value.data,
      });
      setIconScan(value?.isAllProvided);
      setCheckedScanFiles(value?.data?.sent_via_email === 1);
    }
  };

  useEffect(() => {
    scrollToExpandedTab(index, "scans");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="scans"
      headerTitle={scanType(appointmentType?.procedure)}
      index={index}
      iconCheck={iconScan}
    >
      {openCloseTabs[index]?.scans && (
        <Box
          style={customStyle}
          className={`${classes.wrapper} scans-${index}`}
        >
          {
            <>
              <Box className={classes.titleBox}>
                <Typography
                  className={`${classes.titleStyle} ${
                    i18n.language === "ar"
                      ? classes.fontFamilyAlmarai
                      : classes.fontFamilyEina
                  }`}
                >
                  {t("Upload-Scans")}
                </Typography>
              </Box>
              <ScanFiles
                imagePreview={imagePreview}
                appointmentType={appointmentType}
                disable={disable}
                setSuccess={setSuccess}
                appointmentId={appointmentId}
                handleDataFetched={handleDataFetched}
                userId={userId}
                classes={classes}
                setErrorMsg={setErrorMsg}
                scannerPlaceholders={scanner_placeholders}
                t={t}
                disabled={disabled}
                setDisabled={setDisabled}
                handleSave={handleSave}
              />
              {showSendImpressionCheckbox && (
                <>
                  <Checkbox
                    disabled={clinicImpersonated()}
                    label={t("impressions-will-be-sent")}
                    checked={checkedScanFiles}
                    onChange={handleChangeScanFiles}
                  />
                  <Typography className={classes.errorMsg}>
                    {t('impressions-will-be-sent-note')}
                  </Typography>
                </>
              )}
              <Box className={classes.uploadBox}>
                <Button
                  disabled={clinicImpersonated()}
                  className={classes.saveBtn}
                  onClick={() => {}}
                >
                  {success ? t("Saved") : t("Save")}
                </Button>
                <Typography className={classes.errorMsg}>{errorMsg}</Typography>
              </Box>
            </>
          }
        </Box>
      )}
    </ProcedureHeader>
  );
}
